<template>
  <header>
    <div class="d-flex justify-space-between">
      <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
      <v-col cols="5" md="5" class="nav-header d-flex justify-end">
        <v-menu bottom offset-y offset-x>
          <template v-slot:activator="{ props }">
            <v-btn prepend-icon="mdi-account-circle-outline" variant="text" v-bind="props">
              {{ user.name }}
            </v-btn>
          </template>
          <v-list>
            <v-subheader class="d-flex py-3 px-4 mr-2 align-center">
              <v-icon class="mr-4 neutral-500">mdi-account-circle-outline</v-icon>
              <div class="d-flex flex-column">
                <span class="neutral-700">{{ user.name }}</span>
                <span class="text-caption neutral-500 font-weight-light">{{ user.email }}</span>
              </div>
            </v-subheader>
            <v-divider></v-divider>
            <v-list-item v-for="(item, index) in rightMenu" :key="index" :value="index" :to="item.to">
              <template v-slot:prepend>
                <v-icon :icon="item.icon"></v-icon>
              </template>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </div>
    <v-divider class="mx-5"></v-divider>
  </header>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import * as allRoutes from "@/constants/routes";

interface Route {
  text: string;
  href: string;
  disabled?: boolean;
}

interface MenuItem {
  title: string;
  to: string;
  icon: string;
}

interface User {
  name: string;
  email: string;
}

@Options({})
export default class NavHeader extends Vue {
  @Prop({ type: Array, required: true, default: () => [] }) routes!: Route[];
  @Prop({ type: Array, required: true, default: () => [] }) rightMenu!: MenuItem[];
  @Prop({ type: Object, required: true, default: () => ({ name: "não encontrado", email: "não encontrado" }) }) user!: User;

  get breadcrumbs(): Route[] {
    if (!this.isFilledModules()) {
      return this.initialBreadcrumb();
    }

    const currentRoute = this.$router.currentRoute.value.path;

    if (currentRoute === "/home") {
      return this.initialBreadcrumb();
    }

    const previousRoute = this.$router.options.history.state.back;
    const secondBreadcrumb = previousRoute === "/leadtime" ? { text: "Espera", href: "/leadtime", disabled: false } : { text: "Agendamentos", href: "/schedules", disabled: false };

    if (currentRoute.includes("/schedules/store")) {
      return [...this.initialBreadcrumb(), secondBreadcrumb, { text: "Novo Agendamento", href: "/schedules/store", disabled: true }];
    }

    if (currentRoute.includes("/schedules/update")) {
      return [...this.initialBreadcrumb(), secondBreadcrumb, { text: "Editar Agendamento", href: "/schedules/update", disabled: true }];
    }

    const routes = currentRoute
      .split("/")
      .filter((item: any) => !!item)
      .reduce(
        (accumulator: any, current: any) => {
          const routeString = `${accumulator.lastRoute}/${current}`;
          const route = this.routes.find((element) => element.href === routeString);
          if (route) accumulator.routes.push(route);
          accumulator.lastRoute = routeString;
          return accumulator;
        },
        { routes: [] as Route[], lastRoute: "" }
      ).routes;

    return [
      ...this.initialBreadcrumb(),
      ...routes.map((item: any) => ({
        text: item.text,
        href: item.href,
        disabled: currentRoute === item.href,
      })),
    ];
  }

  isFilledModules(): boolean {
    return this.routes.length > 0;
  }

  initialBreadcrumb(): Route[] {
    return [{ text: "Início", href: "/home", disabled: false }];
  }
}
</script>

<style lang="css">
.v-list-item__prepend > .v-icon {
  margin-inline-end: 14px !important;
}
</style>
