<script lang="ts">
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";
import moment from "moment";
import { DeadlineStoreRequest } from "@/models/deadlines/DeadlineStoreRequest";

@Options({})
export default class DeadlinesEditView extends Vue {
  public loading = false;
  public loadingBrands = false;
  public loadingLogs = false;
  public snackbar = {
    show: false,
    text: "",
    success: false,
    timeout: 5000,
  };
  public brands: any = [];
  public id = 0;
  public brand: any = null;
  public brandId = 0;
  public billingDeadline = 0;
  public implementationDeadline = 0;
  public operationalDeadline = 0;
  public pageTitle = "Novo prazo de retirada";
  public currentPage = 1;
  public numberOfPages = 0;
  public itemsPerPage = 0;
  public totalOfRows = 0;
  public pageFrom = 0;
  public pageTo = 1;
  public items: any = [];
  public headers = [
    { title: "Data", align: "start", key: "created_at", sortable: false },
    { title: "Ação", align: "start", key: "action", sortable: false },
    { title: "Usuário", align: "start", key: "userName", sortable: false },
    { title: "Alterações", align: "start", key: "changes", sortable: false },
  ];
  public confirmDialog = false;

  mounted(): void {
    this.loadPageData();
  }

  async loadPageData(): Promise<void> {
    await this.getBrands();

    const id = this.$router.currentRoute.value.params.id ?? 0;
    if (id) {
      this.id = Number(id);
      await this.getById();
      this.pageTitle = "Editar prazo de retirada";
    }

    this.buildTable();
  }

  async getById(): Promise<void> {
    this.loading = true;
    try {
      const response = await services.DeadlinesService.getById(this.id);
      if (response.has_success) {
        this.brandId = response.results.brand_id;
        this.billingDeadline = response.results.billing_deadline;
        this.implementationDeadline = response.results.implementation_deadline;
        this.operationalDeadline = response.results.operational_deadline;
        this.setBrandById(this.brandId);
      }
    } catch (error: any) {
      this.snackbar.text = error.message;
      this.snackbar.success = false;
      this.snackbar.show = true;
    } finally {
      this.loading = false;
    }
  }

  async getBrands() {
    this.loadingBrands = true;
    const response = await services.DeadlinesService.getBrands();
    this.brands = response.results;
    this.loadingBrands = false;
  }

  setBrandById(brandId: number) {
    const selectedBrand = this.brands.find((brand: any) => brand.MarcaID === brandId);
    if (selectedBrand) {
      this.brand = selectedBrand;
    }
  }

  async getLogs(page = 1) {
    try {
      const response = await services.DeadlinesService.logs(this.id, page);
      const results = response.results;
      this.items = results.data;
      this.totalOfRows = results.total;
      this.itemsPerPage = results.per_page;
      this.numberOfPages = results.last_page;
      this.pageFrom = results.from;
      this.pageTo = results.to;
      this.currentPage = results.current_page;
    } catch (error) {
      console.error("Erro ao carregar os logs:", error);
    }
  }

  async buildTable(): Promise<void> {
    this.loadingLogs = true;
    try {
      await this.getLogs(this.currentPage);
    } finally {
      this.loadingLogs = false;
    }
  }

  nextPage(): void {
    this.buildTable();
  }

  prevPage(): void {
    this.buildTable();
  }

  loadPage(value: number): void {
    this.currentPage = value;
    this.buildTable();
  }

  isDate(value: any): boolean {
    return typeof value === "string" && !isNaN(Date.parse(value));
  }

  format_date(value: any) {
    if (value) {
      return moment(String(value)).format("DD/MM/YYYY HH:mm");
    }
  }

  async save(event: any): Promise<void> {
    this.loading = true;

    try {
      if (this.id) {
        this.showConfirmDialog();
      } else {
        const payloadStore = this.createPayload() as DeadlineStoreRequest;
        payloadStore.brandId = this.brand.MarcaID;
        await this.handleResponse(services.DeadlinesService.store(payloadStore as DeadlineStoreRequest));
      }
    } catch (error: any) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }

  public showConfirmDialog() {
    this.confirmDialog = true;
  }

  public async confirmUpdate() {
    this.confirmDialog = false;
    this.loading = true;

    try {
      await this.handleResponse(services.DeadlinesService.update(this.id, this.createPayload()));
    } catch (error: any) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }

  private createPayload() {
    return {
      billingDeadline: this.billingDeadline,
      implementationDeadline: this.implementationDeadline,
      operationalDeadline: this.operationalDeadline,
    };
  }

  private async handleResponse(promise: Promise<any>) {
    const response = await promise;
    this.snackbar.text = response.message;
    this.snackbar.success = true;
    this.snackbar.show = true;
  }

  private handleError(error: any) {
    this.snackbar.text = error.message;
    this.snackbar.success = false;
    this.snackbar.show = true;
  }
}
</script>

<template>
  <v-form validate-on="submit" @submit.prevent="save">
    <v-row>
      <v-col>
        <h1 class="text-h4">{{ pageTitle }}</h1>
      </v-col>
      <v-col align="end">
        <v-btn class="mr-4" variant="text" href="/leadtime#deadlines"> Voltar</v-btn>
        <v-btn class="bg-red rounded-lg float-right" type="submit" :loading="loading">{{ this.id > 0 ? "Atualizar" : "Criar" }}</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-select v-model="brand" :loading="loadingBrands" :items="brands" item-title="Marca" item-value="MarcaID" label="Selecione a marca" return-object single-line :disabled="!!brandId"></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="2">
        <v-text-field v-model.number="billingDeadline" label="Faturamento" type="number" required></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field v-model.number="implementationDeadline" label="Implantação" type="number" required></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field v-model.number="operationalDeadline" label="Operacional" type="number" required></v-text-field>
      </v-col>
    </v-row>
  </v-form>
  <v-divider class="my-4"></v-divider>
  <v-dialog transition="dialog-top-transition" width="auto">
    <template v-slot:activator="{ props: activatorProps }">
      <v-btn v-bind="activatorProps" variant="text"> Visualizar logs </v-btn>
    </template>
    <template v-slot:default="{ isActive }">
      <v-card>
        <v-toolbar title="Logs de alteração">
          <v-card-actions class="justify-end">
            <v-btn icon @click="isActive.value = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-toolbar>
        <v-data-table-server v-model:items-per-page="itemsPerPage" :headers="headers" :items="items" :loading="loadingLogs" item-value="name" class="elevation-1" hide-default-footer>
          <template v-slot:loading>
            <v-sheet class="d-flex align-center justify-center pt-3 pb-3">
              <v-chip> Carregando... </v-chip>
            </v-sheet>
          </template>
          <template v-slot:bottom></template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ format_date(item.value.created_at) }}
          </template>
          <template v-slot:[`item.changes`]="{ item }">
            <div v-if="item.value.changes && item.value.changes.length > 0">
              <div v-for="(change, index) in item.value.changes" :key="index">
                Campo: {{ change.field }};
                <span v-if="change.old">
                  Valor antigo:
                  <span v-if="isDate(change.old)"> {{ format_date(change.old) }} </span>
                  <span v-else> {{ change.old }} </span>;
                </span>
                Valor novo:
                <span v-if="isDate(change.new)">
                  {{ format_date(change.new) }}
                </span>
                <span v-else>
                  {{ change.new }}
                </span>
              </div>
            </div>
            <div v-else>Sem registros.</div>
          </template>
        </v-data-table-server>
        <v-row class="mt-2 d-flex align-center" justify="center">
          <div class="text-center">
            <v-pagination v-model="currentPage" :length="numberOfPages" :total-visible="7" color="orange" @next="nextPage" @prev="prevPage" @update:modelValue="loadPage"></v-pagination>
            <div> {{ pageFrom }} a {{ pageTo }} de {{ totalOfRows }} resultados</div>
          </div>
        </v-row>
      </v-card>
    </template>
  </v-dialog>
  <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout" :color="snackbar.success ? 'success' : 'error'">
    <v-icon class="mr-1"> {{ snackbar.success ? "mdi-check-circle-outline" : "mdi-close-octagon" }} </v-icon> {{ snackbar.text }}
    <template v-slot:actions>
      <v-btn variant="text" @click="this.snackbar.show = false"> Fechar </v-btn>
    </template>
  </v-snackbar>
  <v-container>
    <v-dialog v-model="confirmDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline mt-2">Confirme a atualização</v-card-title>
        <v-card-text>Você tem certeza que deseja atualizar este item?</v-card-text>
        <v-divider class="mt-2 pb-4"></v-divider>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn text @click="confirmDialog = false">Cancelar</v-btn>
          <v-btn class="bg-red rounded-lg" @click="confirmUpdate">Atualizar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.show" :timeout="3000" :color="snackbar.success ? 'success' : 'error'">
      {{ snackbar.text }}
      <v-btn color="white" text @click="snackbar.show = false">Fechar</v-btn>
    </v-snackbar>
  </v-container>
</template>

<style scoped lang="scss"></style>
