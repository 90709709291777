<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h1 class="title">Configurações</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col>
            <v-tabs v-model="tab" @change="onTabChange">
              <v-tab value="schedule">Agendamento</v-tab>
            </v-tabs>
            <v-divider />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-window v-model="tab">
              <v-window-item value="schedule">
                <v-expansion-panels>
                  <v-expansion-panel v-for="item in items" :key="item.id" :title="item.nome + ' - Canal: ' + item.canal_id" class="default-data-base ml-4 mr-4 mb-4">
                    <!-- canal id - {{ item.canal_id }} -->
                    <v-expansion-panel-text>
                      <v-row class="mt-2">
                        <v-col>
                          <v-text-field variant="outlined" v-model="item.nome" label="Descrição" disabled></v-text-field>
                        </v-col>
                        <v-col>
                          <v-text-field variant="outlined" v-model="item.chave" label="Chave" disabled></v-text-field>
                        </v-col>
                        <v-col>
                          <v-text-field variant="outlined" v-model="item.canal_id" label="Canal ID" disabled></v-text-field>
                        </v-col>
                        <v-col>
                          <v-text-field variant="outlined" v-model="item.value" label="Valor"></v-text-field>
                        </v-col>
                        <v-col md="1" class="text-center">
                          <v-btn @click="updateItem(item)" :disabled="btn_disabled" color="movida-orange">Salvar</v-btn>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-window-item>
            </v-window>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
  <v-snackbar v-model="snackbar" :timeout="timeout">
    <v-icon class="ml-1"> mdi-information-outline </v-icon> {{ text }}
    <template v-slot:actions>
      <v-btn variant="text" @click="closeSnack"> Fechar </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";

@Options({
  components: {},
})
export default class RoutesIndexView extends Vue {
  public tab = "schedule";
  public items: Array<any> = [];
  public isLoadingTable = true;
  public headers = [
    { title: "Chave", align: "end", key: "chave", sortable: false },
    { title: "Valor", align: "end", key: "value", sortable: false },
    { title: "Descrição", align: "end", key: "nome", sortable: false },
    { title: "Canal ID", align: "end", key: "canal_id", sortable: false },
    { title: "Data de Criação", align: "end", key: "criacao_data", sortable: false },
    { title: "Data de Atualização", align: "end", key: "atualizado_data", sortable: false },
    { title: "Ações", align: "end", key: "agendamentos_config_valores_id", sortable: false },
  ];
  public snackbar = false;
  public text = "";
  public timeout = 10000;
  public btn_disabled = false;

  mounted(): void {
    this.loadScheduleConfigs();
    this.updateTabFromHash();
    window.addEventListener("hashchange", this.updateTabFromHash);
  }

  async loadScheduleConfigs(): Promise<void> {
    this.changeLoading();
    try {
      const response = await services.ScheduleService.getAllScheduleConfigs();
      this.items = response.results;
    } catch (e) {
      console.log("ERRO", e);
    }
    this.changeLoading();
  }

  beforeDestroy() {
    window.removeEventListener("hashchange", this.updateTabFromHash);
  }

  async updateItem(item: any): Promise<void> {
    const id = item.agendamentos_config_valores_id;
    const value = item.value;
    if (id && value) {
      try {
        await services.ScheduleService.updateScheduleConfig(id, value);
        this.text = "Configuração atualizada com sucesso!";
        this.snackbar = true;
        this.btn_disabled = true;
        this.$router.push("/settings");
      } catch (e) {
        this.snackbar = true;
        this.btn_disabled = false;
        this.text = "Erro ao atualizar configuração.";
      }
    }
  }

  closeSnack(): void {
    this.snackbar = false;
    this.$router.push("/settings");
  }

  updateTabFromHash(): void {
    const hash = window.location.hash.replace("#", "");
    if (hash === "deadlines" || hash === "schedule") {
      this.tab = hash;
    } else {
      this.tab = "schedule";
    }
  }

  onTabChange(tab: string): void {
    window.location.hash = `#${tab}`;
  }

  changeLoading(): void {
    this.isLoadingTable = !this.isLoadingTable;
  }
}
</script>

<style lang="css">
.default-data-base {
  border: solid #efecec 0.4px;
}

.w-3 {
  width: 10px;
}

.h-3 {
  height: 10px;
}
</style>
