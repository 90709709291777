<script lang="ts">
import { Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import * as services from "@/services";
import moment from "moment";

class Props {
  show?: boolean;
}

export default class LogsDialogComponent extends Vue.with(Props) {
  public loading = false;
  public currentPage = 1;
  public numberOfPages = 0;
  public itemsPerPage = 0;
  public totalOfRows = 0;
  public pageFrom = 0;
  public pageTo = 1;
  public items: any = [];
  public headers = [
    { title: "Data", align: "start", key: "created_at", sortable: false },
    { title: "Usuário", align: "start", key: "userName", sortable: false },
    { title: "Antigo", align: "start", key: "old", sortable: false },
    { title: "Novo", align: "start", key: "new", sortable: false },
  ];

  public closeDialog(): void {
    this.$emit("update:show", false);
  }

  @Watch("show")
  onShowChanged(value: boolean) {
    if (value) {
      this.buildTable();
    }
  }

  async buildTable(): Promise<void> {
    this.loading = true;
    try {
      await this.getLogs(this.currentPage);
    } finally {
      this.loading = false;
    }
  }

  async getLogs(page = 1) {
    try {
      const response = await services.DeadlinesService.getCommunicationDeadlineLogs(page);
      const results = response.results;
      if (results) {
        this.items = results.data;
        this.totalOfRows = results.total;
        this.itemsPerPage = results.per_page;
        this.numberOfPages = results.last_page;
        this.pageFrom = results.from;
        this.pageTo = results.to;
        this.currentPage = results.current_page;
      }
    } catch (error) {
      console.error("Erro ao carregar os logs:", error);
    }
  }

  format_date(value: any) {
    if (value) {
      return moment(String(value)).format("DD/MM/YYYY HH:mm");
    }
  }
}
</script>

<template>
  <v-dialog v-model="show" transition="dialog-top-transition" width="600">
    <v-card>
      <v-toolbar title="Logs de alteração">
        <v-card-actions class="justify-end">
          <v-btn icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-toolbar>
      <v-data-table-server v-model:items-per-page="itemsPerPage" :headers="headers" :items="items" :loading="loading" item-value="name" class="elevation-1" hide-default-footer>
        <template v-slot:loading>
          <v-sheet class="d-flex align-center justify-center pt-3 pb-3">
            <v-chip> Carregando... </v-chip>
          </v-sheet>
        </template>
        <template v-slot:bottom></template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ format_date(item.value.created_at) }}
        </template>
        <template v-slot:[`item.old`]="{ item }">
          {{ item.value.changes.old }}
        </template>
        <template v-slot:[`item.new`]="{ item }">
          {{ item.value.changes.new }}
        </template>
      </v-data-table-server>
      <v-row class="my-2 d-flex align-center" justify="center">
        <div class="text-center">
          <v-pagination v-model="currentPage" :length="numberOfPages" :total-visible="7" color="orange" @next="nextPage" @prev="prevPage" @update:modelValue="loadPage"></v-pagination>
          <div> {{ pageFrom }} a {{ pageTo }} de {{ totalOfRows }} resultados</div>
        </div>
      </v-row>
    </v-card>
  </v-dialog>
</template>
