import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createElementVNode("h1", { class: "title" }, "Configurações", -1)
            ])),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_tabs, {
                        modelValue: _ctx.tab,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
                        onChange: _ctx.onTabChange
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_tab, { value: "schedule" }, {
                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                              _createTextVNode("Agendamento")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue", "onChange"]),
                      _createVNode(_component_v_divider)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_window, {
                        modelValue: _ctx.tab,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tab) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_window_item, { value: "schedule" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_expansion_panels, null, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                                    return (_openBlock(), _createBlock(_component_v_expansion_panel, {
                                      key: item.id,
                                      title: item.nome + ' - Canal: ' + item.canal_id,
                                      class: "default-data-base ml-4 mr-4 mb-4"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_expansion_panel_text, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_row, { class: "mt-2" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_col, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_text_field, {
                                                      variant: "outlined",
                                                      modelValue: item.nome,
                                                      "onUpdate:modelValue": ($event: any) => ((item.nome) = $event),
                                                      label: "Descrição",
                                                      disabled: ""
                                                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_v_col, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_text_field, {
                                                      variant: "outlined",
                                                      modelValue: item.chave,
                                                      "onUpdate:modelValue": ($event: any) => ((item.chave) = $event),
                                                      label: "Chave",
                                                      disabled: ""
                                                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_v_col, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_text_field, {
                                                      variant: "outlined",
                                                      modelValue: item.canal_id,
                                                      "onUpdate:modelValue": ($event: any) => ((item.canal_id) = $event),
                                                      label: "Canal ID",
                                                      disabled: ""
                                                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_v_col, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_text_field, {
                                                      variant: "outlined",
                                                      modelValue: item.value,
                                                      "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                                                      label: "Valor"
                                                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_v_col, {
                                                  md: "1",
                                                  class: "text-center"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_btn, {
                                                      onClick: ($event: any) => (_ctx.updateItem(item)),
                                                      disabled: _ctx.btn_disabled,
                                                      color: "movida-orange"
                                                    }, {
                                                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                                                        _createTextVNode("Salvar")
                                                      ])),
                                                      _: 2
                                                    }, 1032, ["onClick", "disabled"])
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1032, ["title"]))
                                  }), 128))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.snackbar,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.snackbar) = $event)),
      timeout: _ctx.timeout
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          variant: "text",
          onClick: _ctx.closeSnack
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode(" Fechar ")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_icon, { class: "ml-1" }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode(" mdi-information-outline ")
          ])),
          _: 1
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.text) + " ", 1)
      ]),
      _: 1
    }, 8, ["modelValue", "timeout"])
  ], 64))
}