import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_pagination = _resolveComponent("v-pagination")!
  const _component_LogsDialog = _resolveComponent("LogsDialog")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, null, {
          default: _withCtx(() => _cache[13] || (_cache[13] = [
            _createElementVNode("h2", { class: "title" }, "Prazo de Retirada", -1)
          ])),
          _: 1
        }),
        _createVNode(_component_v_col, { class: "text-right d-flex align-center justify-end" }, {
          default: _withCtx(() => [
            _cache[17] || (_cache[17] = _createElementVNode("span", { class: "font-weight-bold" }, "Prazo de comunicação:", -1)),
            _createVNode(_component_v_sheet, {
              border: "",
              class: "px-4 py-1 ml-2 rounded",
              elevation: "1"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.communicationDeadline), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_btn, {
              icon: "",
              size: "small",
              variant: "text",
              onClick: _ctx.showCommunicationDeadlineDialog
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                    _createTextVNode("mdi-pencil")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_v_btn, {
              class: "ml-6",
              color: "movida-orange",
              href: "/deadlines/store"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, { left: "" }, {
                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                    _createTextVNode("mdi-plus")
                  ])),
                  _: 1
                }),
                _cache[16] || (_cache[16] = _createTextVNode(" Adicionar "))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_data_table_server, {
              "items-per-page": _ctx.itemsPerPage,
              "onUpdate:itemsPerPage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.itemsPerPage) = $event)),
              headers: _ctx.headers,
              items: _ctx.items,
              loading: _ctx.loading,
              "item-value": "name",
              class: "elevation-1",
              "hide-default-footer": ""
            }, {
              loading: _withCtx(() => [
                _createVNode(_component_v_sheet, { class: "d-flex align-center text-center justify-center pt-3 pb-3" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_chip, null, {
                      default: _withCtx(() => _cache[18] || (_cache[18] = [
                        _createTextVNode(" Carregando... ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              bottom: _withCtx(() => _cache[19] || (_cache[19] = [])),
              [`item.updated_at`]: _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(_ctx.format_date(item.value.updated_at)), 1)
              ]),
              [`item.options`]: _withCtx(({ item }) => [
                _createVNode(_component_v_menu, {
                  bottom: "",
                  left: ""
                }, {
                  activator: _withCtx(({ on, props }) => [
                    _createVNode(_component_v_btn, _mergeProps({
                      dark: "",
                      icon: ""
                    }, props, _toHandlers(on), { style: {"box-shadow":"none"} }), {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => _cache[20] || (_cache[20] = [
                            _createTextVNode("mdi-dots-vertical")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1040)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_list, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item, {
                          href: '/deadlines/update/' + item.value.id
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, null, {
                              default: _withCtx(() => _cache[21] || (_cache[21] = [
                                _createTextVNode("Editar")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["href"]),
                        _createVNode(_component_v_list_item, {
                          onClick: ($event: any) => (_ctx.remove(item.value.id))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, null, {
                              default: _withCtx(() => _cache[22] || (_cache[22] = [
                                _createTextVNode("Remover")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["items-per-page", "headers", "items", "loading"]),
            _createVNode(_component_v_row, {
              class: "mt-2 d-flex align-center",
              justify: "center"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_v_pagination, {
                    modelValue: _ctx.currentPage,
                    "onUpdate:modelValue": [
                      _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentPage) = $event)),
                      _ctx.loadPage
                    ],
                    length: _ctx.numberOfPages,
                    "total-visible": 7,
                    color: "orange",
                    onNext: _ctx.nextPage,
                    onPrev: _ctx.prevPage
                  }, null, 8, ["modelValue", "length", "onNext", "onPrev", "onUpdate:modelValue"]),
                  _createElementVNode("div", null, _toDisplayString(_ctx.pageFrom) + " a " + _toDisplayString(_ctx.pageTo) + " de " + _toDisplayString(_ctx.totalOfRows) + " resultados", 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_btn, {
      variant: "text",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (this.showLogs = true))
    }, {
      default: _withCtx(() => _cache[23] || (_cache[23] = [
        _createTextVNode("Visualizar logs: Prazo de comunicação")
      ])),
      _: 1
    }),
    _createVNode(_component_LogsDialog, {
      show: this.showLogs,
      "onUpdate:show": _cache[3] || (_cache[3] = ($event: any) => ((this.showLogs) = $event))
    }, null, 8, ["show"]),
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.confirmDialog,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.confirmDialog) = $event)),
          "max-width": "500px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "headline mt-2" }, {
                  default: _withCtx(() => _cache[24] || (_cache[24] = [
                    _createTextVNode("Confirme a remoção")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => _cache[25] || (_cache[25] = [
                    _createTextVNode("Você tem certeza que deseja remover este item?")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_divider, { class: "mt-2 pb-4" }),
                _createVNode(_component_v_card_actions, { class: "pb-4" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      text: "",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.confirmDialog = false))
                    }, {
                      default: _withCtx(() => _cache[26] || (_cache[26] = [
                        _createTextVNode("Cancelar")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      class: "bg-red rounded-lg",
                      onClick: _ctx.confirmDelete
                    }, {
                      default: _withCtx(() => _cache[27] || (_cache[27] = [
                        _createTextVNode("Remover Definitivamente")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_snackbar, {
          modelValue: _ctx.snackbar,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.snackbar) = $event)),
          timeout: 3000,
          color: _ctx.snackbarColor
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.snackbarText) + " ", 1),
            _createVNode(_component_v_btn, {
              color: "white",
              text: "",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.snackbar = false))
            }, {
              default: _withCtx(() => _cache[28] || (_cache[28] = [
                _createTextVNode("Close")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue", "color"])
      ]),
      _: 1
    }),
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.editDialog,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editDialog) = $event)),
          "max-width": "500px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "headline mt-2" }, {
                  default: _withCtx(() => _cache[29] || (_cache[29] = [
                    _createTextVNode("Prazo de comunicação")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_divider, { class: "mt-2 pb-4" }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => _cache[30] || (_cache[30] = [
                    _createTextVNode("Atualize o prazo de comunicação com o cliente.")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_card, { class: "pb-4 px-4" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      modelValue: _ctx.inputCommunicationDeadline,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.inputCommunicationDeadline) = $event)),
                      label: "Prazo de comunicação",
                      type: "number",
                      min: "0"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, { class: "pb-4" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      text: "",
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.editDialog = false))
                    }, {
                      default: _withCtx(() => _cache[31] || (_cache[31] = [
                        _createTextVNode("Cancelar")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      class: "bg-red rounded-lg",
                      onClick: _ctx.editCommunicationDeadline,
                      disabled: _ctx.isLoading
                    }, {
                      default: _withCtx(() => [
                        (_ctx.isLoading)
                          ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                              key: 0,
                              indeterminate: "",
                              size: "20",
                              color: "white"
                            }))
                          : (_openBlock(), _createElementBlock("span", _hoisted_2, "Salvar"))
                      ]),
                      _: 1
                    }, 8, ["onClick", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_snackbar, {
          modelValue: _ctx.snackbar,
          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.snackbar) = $event)),
          timeout: 3000,
          color: _ctx.snackbarColor
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.snackbarText) + " ", 1),
            _createVNode(_component_v_btn, {
              color: "white",
              text: "",
              onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.snackbar = false))
            }, {
              default: _withCtx(() => _cache[32] || (_cache[32] = [
                _createTextVNode("Close")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue", "color"])
      ]),
      _: 1
    })
  ], 64))
}