// eslint-disable-next-line
const allStatus = [
  "Ag. Acionamento transporte",
  "Agendar",
  "Agendado",
  "Cancelado",
  "Cancelar agendamento",
  "Em transporte",
  "Entregue",
  "Implantado",
  "Notificar cliente",
  "Notificação emitida",
  "Pendente Frota",
  "Preparação",
  "Reagendar",
  "Solicitar cancelamento",
  "Tratativa Operacional",
  "Veículo avariado",
];

allStatus.sort();

export default allStatus;
