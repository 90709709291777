import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_pagination = _resolveComponent("v-pagination")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.show) = $event)),
    transition: "dialog-top-transition",
    width: "600"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, { title: "Logs de alteração" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_actions, { class: "justify-end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    icon: "",
                    onClick: _ctx.closeDialog
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode("mdi-close")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_data_table_server, {
            "items-per-page": _ctx.itemsPerPage,
            "onUpdate:itemsPerPage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.itemsPerPage) = $event)),
            headers: _ctx.headers,
            items: _ctx.items,
            loading: _ctx.loading,
            "item-value": "name",
            class: "elevation-1",
            "hide-default-footer": ""
          }, {
            loading: _withCtx(() => [
              _createVNode(_component_v_sheet, { class: "d-flex align-center justify-center pt-3 pb-3" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_chip, null, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode(" Carregando... ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            bottom: _withCtx(() => _cache[5] || (_cache[5] = [])),
            [`item.created_at`]: _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(_ctx.format_date(item.value.created_at)), 1)
            ]),
            [`item.old`]: _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(item.value.changes.old), 1)
            ]),
            [`item.new`]: _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(item.value.changes.new), 1)
            ]),
            _: 2
          }, 1032, ["items-per-page", "headers", "items", "loading"]),
          _createVNode(_component_v_row, {
            class: "my-2 d-flex align-center",
            justify: "center"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_v_pagination, {
                  modelValue: _ctx.currentPage,
                  "onUpdate:modelValue": [
                    _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentPage) = $event)),
                    _ctx.loadPage
                  ],
                  length: _ctx.numberOfPages,
                  "total-visible": 7,
                  color: "orange",
                  onNext: _ctx.nextPage,
                  onPrev: _ctx.prevPage
                }, null, 8, ["modelValue", "length", "onNext", "onPrev", "onUpdate:modelValue"]),
                _createElementVNode("div", null, _toDisplayString(_ctx.pageFrom) + " a " + _toDisplayString(_ctx.pageTo) + " de " + _toDisplayString(_ctx.totalOfRows) + " resultados", 1)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}