import { BaseService } from "../BaseService";
import { DeadlineStoreRequest } from "@/models/deadlines/DeadlineStoreRequest";
import { DeadlineUpdateRequest } from "@/models/deadlines/DeadlineUpdateRequest";
import { CommunicationDeadlineUpdateRequest } from "@/models/deadlines/CommunicationDeadlineUpdateRequest";

class DeadlinesService extends BaseService {
  async getBrands() {
    const route = `/api/v1/brands?list=all`;
    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }

  async getAll(page = 1) {
    const route = `/api/v1/brands/deadlines?page=${page}`;
    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }

  async getById(id: number) {
    const route = `/api/v1/brands/deadlines/${id}`;
    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }

  async store(data: DeadlineStoreRequest) {
    const route = `/api/v1/brands/deadlines`;
    const response = await this.post(route, data);
    return response.data;
  }

  async update(id: number, data: DeadlineUpdateRequest) {
    const route = `/api/v1/brands/deadlines/${id}`;
    const response = await this.put(route, data);
    return response.data;
  }

  async destroy(id: number) {
    const route = `/api/v1/brands/deadlines/${id}`;
    const response = await this.instance.delete(route, this.getConfigHeaders());
    return response.data;
  }

  async logs(id: number, page = 1) {
    const route = `/api/v1/brands/deadlines/${id}/logs?page=${page}`;
    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }

  async getCommunicationDeadline() {
    const route = `/api/v1/brands/deadlines/communication/`;
    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }

  async updateCommunicationDeadline(data: CommunicationDeadlineUpdateRequest) {
    const route = `/api/v1/brands/deadlines/communication/`;
    const response = await this.put(route, data);
    return response.data;
  }

  async getCommunicationDeadlineLogs(page = 1) {
    const route = `/api/v1/brands/deadlines/communication/logs?page=${page}`;
    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }
}

export default new DeadlinesService("", "");
