import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between" }
const _hoisted_2 = { class: "d-flex flex-column" }
const _hoisted_3 = { class: "neutral-700" }
const _hoisted_4 = { class: "text-caption neutral-500 font-weight-light" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_breadcrumbs = _resolveComponent("v-breadcrumbs")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_subheader = _resolveComponent("v-subheader")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createElementBlock("header", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_breadcrumbs, {
        items: _ctx.breadcrumbs,
        divider: "/"
      }, null, 8, ["items"]),
      _createVNode(_component_v_col, {
        cols: "5",
        md: "5",
        class: "nav-header d-flex justify-end"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_menu, {
            bottom: "",
            "offset-y": "",
            "offset-x": ""
          }, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_v_btn, _mergeProps({
                "prepend-icon": "mdi-account-circle-outline",
                variant: "text"
              }, props), {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.user.name), 1)
                ]),
                _: 2
              }, 1040)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_list, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_subheader, { class: "d-flex py-3 px-4 mr-2 align-center" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, { class: "mr-4 neutral-500" }, {
                        default: _withCtx(() => _cache[0] || (_cache[0] = [
                          _createTextVNode("mdi-account-circle-outline")
                        ])),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.user.name), 1),
                        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.user.email), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_divider),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rightMenu, (item, index) => {
                    return (_openBlock(), _createBlock(_component_v_list_item, {
                      key: index,
                      value: index,
                      to: item.to
                    }, {
                      prepend: _withCtx(() => [
                        _createVNode(_component_v_icon, {
                          icon: item.icon
                        }, null, 8, ["icon"])
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item_title, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.title), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["value", "to"]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_v_divider, { class: "mx-5" })
  ]))
}