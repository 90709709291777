import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "d-flex flex-column items-center align-center" }
const _hoisted_2 = { class: "text-decoration-none" }
const _hoisted_3 = { class: "d-flex flex-column items-center align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, {
    "validate-on": "submit lazy",
    onSubmit: _withModifiers(_ctx.store, ["prevent"])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_col, {
          class: "d-flex justify-space-between",
          sm: "12",
          md: "6"
        }, {
          default: _withCtx(() => [
            _cache[13] || (_cache[13] = _createElementVNode("h1", { class: "" }, "Novo agendamentos", -1)),
            _createElementVNode("a", _hoisted_2, [
              _createVNode(_component_v_btn, {
                class: "text-white mr-2",
                color: "grey",
                onClick: _ctx.backToPreviousPage
              }, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createTextVNode("Voltar")
                ])),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_btn, {
                class: "text-white",
                color: "movida-orange",
                type: "submit",
                disabled: _ctx.btn_disabled
              }, {
                default: _withCtx(() => _cache[12] || (_cache[12] = [
                  _createTextVNode("Salvar")
                ])),
                _: 1
              }, 8, ["disabled"])
            ])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_v_col, {
          sm: "12",
          md: "6"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_autocomplete, {
              modelValue: _ctx.orderInfo,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((_ctx.orderInfo) = $event)),
                _ctx.selectBranch
              ],
              search: _ctx.search,
              "onUpdate:search": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
              rules: _ctx.rulesRequired,
              loading: _ctx.loading || _ctx.loadingOrder,
              items: _ctx.orders,
              "item-title": "label",
              "item-value": "id",
              density: "comfortable",
              "hide-no-data": "",
              "hide-details": "",
              label: "Pedido",
              variant: "solo",
              "append-inner-icon": "mdi-magnify"
            }, null, 8, ["modelValue", "search", "rules", "loading", "items", "onUpdate:modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          sm: "12",
          md: "6"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_autocomplete, {
              modelValue: _ctx.branchId,
              "onUpdate:modelValue": [
                _cache[2] || (_cache[2] = ($event: any) => ((_ctx.branchId) = $event)),
                _ctx.getAvailability
              ],
              label: "Loja de Retirada",
              loading: _ctx.loading || _ctx.loadingBranch,
              rules: _ctx.rulesRequired,
              items: _ctx.branchs,
              "item-title": "nome",
              "item-value": "id",
              variant: "solo",
              "hide-details": ""
            }, null, 8, ["modelValue", "loading", "rules", "items", "onUpdate:modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          sm: "12",
          md: "6"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_autocomplete, {
              modelValue: _ctx.closestBranchId,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.closestBranchId) = $event)),
              label: "Loja atual do veículo",
              loading: _ctx.loading || _ctx.loadingBranch,
              rules: _ctx.rulesRequired,
              items: _ctx.branchs,
              "item-title": "nome",
              "item-value": "id",
              variant: "solo"
            }, null, 8, ["modelValue", "loading", "rules", "items"]),
            _createVNode(_component_v_switch, {
              modelValue: _ctx.isClosestBranch,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isClosestBranch) = $event)),
              label: "Usar loja atual como loja de retirada",
              class: "mt-2",
              color: "movida-orange",
              "hide-details": "",
              density: "compact",
              onChange: _ctx.dialogBranch
            }, null, 8, ["modelValue", "onChange"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          sm: "12",
          md: "6"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_autocomplete, {
              modelValue: _ctx.serviceId,
              "onUpdate:modelValue": [
                _cache[5] || (_cache[5] = ($event: any) => ((_ctx.serviceId) = $event)),
                _ctx.getAvailability
              ],
              label: "Tipo de Agendamento",
              loading: _ctx.loading || _ctx.loadingService,
              rules: _ctx.rulesRequired,
              items: _ctx.services,
              "item-title": "nome",
              "item-value": "servico_id",
              variant: "solo"
            }, null, 8, ["modelValue", "loading", "rules", "items", "onUpdate:modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          sm: "12",
          md: "6"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_divider)
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          sm: "12",
          md: "6"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_VueDatePicker, {
              modelValue: _ctx.date,
              "onUpdate:modelValue": [
                _cache[6] || (_cache[6] = ($event: any) => ((_ctx.date) = $event)),
                _ctx.getHourAvailability
              ],
              "input-class-name": "datepickerForm",
              locale: "pt-br",
              "select-text": "selecionar",
              "cancel-text": "cancelar",
              format: _ctx.format,
              "enable-time-picker": false,
              "allowed-dates": _ctx.allowedDates
            }, null, 8, ["modelValue", "format", "allowed-dates", "onUpdate:modelValue"]),
            (_ctx.loading || _ctx.loadingDate)
              ? (_openBlock(), _createBlock(_component_v_progress_linear, {
                  key: 0,
                  indeterminate: ""
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          sm: "12",
          md: "6"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_select, {
              modelValue: _ctx.hour,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.hour) = $event)),
              items: _ctx.allowedHour,
              loading: _ctx.loading || _ctx.loadingHour,
              variant: "solo",
              rules: _ctx.rulesRequired,
              "append-inner-icon": "mdi-clock"
            }, null, 8, ["modelValue", "items", "loading", "rules"])
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.confirmDialog,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.confirmDialog) = $event)),
        "max-width": "500px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "headline" }, {
                default: _withCtx(() => _cache[14] || (_cache[14] = [
                  _createTextVNode("Confirmação")
                ])),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createTextVNode("O veículo será retirado na loja " + _toDisplayString(_ctx.closestBranchName) + "?", 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _cache[8] || (_cache[8] = ($event: any) => {
              _ctx.confirmDialog = false;
              _ctx.isClosestBranch = false;
            })
                  }, {
                    default: _withCtx(() => _cache[15] || (_cache[15] = [
                      _createTextVNode("Não")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _ctx.changeBranch
                  }, {
                    default: _withCtx(() => _cache[16] || (_cache[16] = [
                      _createTextVNode("Sim")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_snackbar, {
        modelValue: _ctx.snackbar,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.snackbar) = $event)),
        timeout: _ctx.timeout
      }, {
        actions: _withCtx(() => [
          _createVNode(_component_v_btn, {
            variant: "text",
            onClick: _ctx.closeSnack
          }, {
            default: _withCtx(() => _cache[18] || (_cache[18] = [
              _createTextVNode(" Fechar ")
            ])),
            _: 1
          }, 8, ["onClick"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_icon, { class: "ml-1" }, {
            default: _withCtx(() => _cache[17] || (_cache[17] = [
              _createTextVNode(" mdi-information-outline ")
            ])),
            _: 1
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.text) + " ", 1)
        ]),
        _: 1
      }, 8, ["modelValue", "timeout"])
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}