<template>
  <v-row>
    <v-col>
      <v-text-field v-model="privateFilters.search" label="Buscar..." prepend-inner-icon="mdi-magnify" title="Pesquise por pedido, reserva, contrato, placa ou CPF." variant="outlined" density="compact" :error-messages="error.id" @blur="search()"></v-text-field>
    </v-col>
    <v-col>
      <v-autocomplete v-model="privateFilters.status" label="Status" :clearable="true" :items="allStatus" item-title="Status" item-value="Status" variant="outlined" density="compact" multiple :error-messages="error.status">
        <template v-slot:selection="data">
          <div v-if="privateFilters.status.length < 2">
            {{ data.item.title.slice(0, 7) + "..." }}
          </div>
          <div v-else-if="data.index === 0">
            {{ `${privateFilters.status.length} status` }}
          </div>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col>
      <v-text-field v-model="privateFilters.startDate" label="Data Inicio" type="date" variant="outlined" density="compact" :min="min" :error-messages="error.startDate"></v-text-field>
    </v-col>
    <v-col>
      <v-text-field v-model="privateFilters.endDate" label="Data Fim" type="date" variant="outlined" density="compact" :min="min" :error-messages="error.endDate"></v-text-field>
    </v-col>
    <v-col class="filters-buttons">
      <v-btn color="movida-orange" @click="search()" append-icon="mdi-magnify"> Buscar </v-btn>
      <v-btn color="movida-orange" @click="expand = !expand" title="Mais filtros">
        <v-icon v-show="!expand">mdi-filter-menu</v-icon>
        <v-icon v-show="expand">mdi-filter-minus</v-icon>
      </v-btn>
      <v-btn color="movida-orange" title="Limpar filtros" @click="clear()">
        <v-icon>mdi-filter-remove</v-icon>
      </v-btn>
    </v-col>
  </v-row>
  <v-expand-transition>
    <v-row v-show="expand">
      <v-col>
        <v-select v-model="privateFilters.type" :clearable="true" :items="allTypes" label="Tipo" variant="outlined" density="compact" :error-messages="error.type"></v-select>
      </v-col>
      <v-col>
        <v-select v-model="privateFilters.modality" :clearable="true" :items="allModalities" label="Modalidade" variant="outlined" density="compact" :error-messages="error.modality"></v-select>
      </v-col>
      <v-col>
        <v-select v-model="privateFilters.options" :items="options" :clearable="true" label="Opções" variant="outlined" density="compact" multiple :error-messages="error.options">
          <template v-slot:selection="data">
            <div v-if="privateFilters.options.length < 2">
              {{ data.item.title.slice(0, 7) + "..." }}
            </div>
            <div v-else-if="data.index === 0">
              {{ `${privateFilters.options.length} opções` }}
            </div>
          </template>
        </v-select>
      </v-col>
      <v-col>
        <v-select v-model="privateFilters.dateType" :clearable="true" :items="allDataTypes" label="Tipo de Data" variant="outlined" density="compact" :error-messages="error.dateType"></v-select>
      </v-col>
    </v-row>
  </v-expand-transition>
</template>
<script lang="ts">
import { FiltersOrder } from "@/models/backoffice/FiltersOrder";
import { title } from "process";
import { Vue, prop } from "vue-class-component";
import { Watch } from "vue-property-decorator";

class Props {
  filters?: FiltersOrder;
  allStatus?: any;
  error?: any;
}
export default class FiltersOrdersComponent extends Vue.with(Props) {
  public privateFilters: FiltersOrder = {
    search: "",
    status: [],
    type: [],
    modality: [],
    options: [],
    startDate: "",
    endDate: "",
    document: "",
    dateType: "data_agendamento",
  };
  public expand = false;
  public allTypes = ["Operacional", "Super Vip"];
  public allModalities = ["Estoque", "Sob Demanda", "Renovação - Sob demanda", "Renovação - Estoque", "Retenção"];
  public allDataTypes = [
    {
      title: "Data de Agendamento",
      value: "data_agendamento",
    },
    {
      title: "Data do Pedido",
      value: "data_criacao_pedido",
    },
  ];
  public options = ["Placa em duplicidade", "Divergência final de placa", "Divergência loja de retirada"];
  public min = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().split("T")[0];
  data(): object {
    return {
      privateFilters: this.filters,
    };
  }

  search() {
    this.$emit("search", this.privateFilters);
  }

  getFilters() {
    return this.privateFilters;
  }

  clear() {
    this.privateFilters = {
      search: "",
      status: [],
      type: [],
      modality: [],
      options: [],
      startDate: "",
      endDate: "",
      document: "",
      dateType: "data_agendamento",
    };
  }

  @Watch("privateFilters")
  onPrivateFiltersChanged(val: number) {
    this.$emit("update:filters", val);
  }
}
</script>
<style>
.filters-buttons {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: start;
}
</style>
